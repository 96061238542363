import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Rate from './components/Rate';

import { css } from '@emotion/css';

const YourQuotes = ({ quotes, loading = true }) => {
  const { info } = useSelector((state) => state.form);

  const [waitMessage, setWaitMessage] = useState('Retrieving the best rates..');
  const [showRates, setShowRates] = useState(false);

  const quoteStyle = css`
    .rate {
      @media screen and (min-width: 768px) {
        &:first-child {
          border-radius: 5px 5px 0 0;
        }
        &:last-child {
          border-radius: 0 0 5px 5px;
        }

        margin-left: inherit;
        margin-right: inherit;
      }
      margin-bottom: -1px;
      margin-right: -1px;
      margin-left: -1px;
    }
  `;

  const fetchRates = () => {
    setTimeout(() => {
      setWaitMessage('Hang tight..');
    }, 2800);
    setTimeout(() => {
      setShowRates(true);
    }, 4000);
  };

  const hasRates = quotes && quotes.length > 0;

  useEffect(() => {
    fetchRates();
  }, []);

  return showRates && !loading ? (
    <section className="max-w-5xl mx-auto w-full">
      <header className=" p-5 md:p-8">
        <div className="text-sm text-gray-800">{quotes.length} match found</div>
        {hasRates && (
          <div className="text-xl ">
            {info.firstname}, Check your rates for potential savings!
          </div>
        )}
      </header>
      <section className={`${quoteStyle} mb-12 md:p-8 md:pt-0`}>
        {hasRates ? (
          quotes.map((quote, i) => (
            <Rate rate={quote} key={`rt-${i}`} total={quotes.length} />
          ))
        ) : (
          <p className="text-xl leading-relaxed md:text-2xl mt-6 text-center text-gray-900">
            We&apos;re sorry, Wayless Quotes is not currently available in your
            area. <br /> Please try again later!
          </p>
        )}
      </section>
    </section>
  ) : (
    <div className="py-12 md:py-32 flex flex-col items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="60"
        viewBox="0 0 105 105"
        fill="#2E3192"
      >
        <circle cx="12.5" cy="12.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="0s"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="12.5" cy="52.5" r="12.5" fillOpacity=".5">
          <animate
            attributeName="fill-opacity"
            begin="100ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="52.5" cy="12.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="300ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="52.5" cy="52.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="600ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="92.5" cy="12.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="800ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="92.5" cy="52.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="400ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="12.5" cy="92.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="700ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="52.5" cy="92.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="500ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="92.5" cy="92.5" r="12.5">
          <animate
            attributeName="fill-opacity"
            begin="200ms"
            dur="1s"
            values="1;.2;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
      <div className="mt-4 sm:mt-6 sm:text-lg font-medium">{waitMessage}</div>
    </div>
  );
};

YourQuotes.propTypes = {
  quotes: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};

export default YourQuotes;
