import React, { useDebugValue, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import amplitude from 'amplitude-js';
import funnels from '@/data/funnels';
import { css } from '@emotion/css';

import {
  setQuestions,
  setCurrentQuestion, // TEMP
  setAllAnswers,
  setTotal,
  setAnswer,
  goToNextQuestion,
  goToPreviousQuestion,
  addError,
} from '@/store/slices/formSlice';
import ProgressBar from '@/components/ProgressBar';
import PreviousQuestion from '@/components/PreviousQuestion';
import LoadingSkeleton from '@/components/LoadingSkeleton';
import FormButton from '@/components/FormButton';
import TermsAndConditions from '@/components/TermsAndConditions';
import Notification from '@/components/Notification';
import QuoteSelect from './components/QuoteSelect';
import QuoteStateMedicareSelect from './components/QuoteStateMedicareSelect';
import QuoteMultiSelect from './components/QuoteMultiSelect';
import QuoteLevelSelect from './components/QuoteLevelSelect';
import QuoteFormItem from './components/QuoteFormItem';
import QuoteAddress from './components/QuoteAddress';
import QuoteVehicleModel from './components/QuoteVehicleModel';
import QuoteAddChildren from './components/QuoteAddChildren';

const QuoteForm = ({ onShowQuotes, type }) => {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(true);
  const [fadeIn, setFadeIn] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [isTransitioningQuestions, setIsTransitioningQuestions] = useState(
    false
  );
  const [notificationMsg, setNotificationMsg] = useState('');

  const { questions, currentQuestion, answers, total, errors } = useSelector(
    (store) => store.form
  );

  const quoteItemsWrapperStyles = css`
    min-height: 50px;
  `;

  const {
    label,
    itemLabel,
    formType,
    options,
    otherOptions,
    otherOptionsLabel,
    autoNext,
    placeholder,
    btnLabel,
    name,
    validate,
    notifications = null,
  } = questions[`q${currentQuestion}`];

  const handleOnChange = (answer, subId) => {
    const answerUpdate = {
      qId: currentQuestion,
    };

    if (subId) {
      answerUpdate.answer = { ...answers[currentQuestion] } || {};
      answerUpdate.answer[subId] = answer;
    } else {
      answerUpdate.answer = answer;
    }

    dispatch(setAnswer(answerUpdate));

    if (autoNext) {
      nextQuestion();
    }
  };

  const nextQuestion = () => {
    if (total === currentQuestion) {
      onShowQuotes();
      return;
    }

    // setLoading(true);
    setFadeOut(true);
    setFadeIn(false);
    setTimeout(() => {
      setIsTransitioningQuestions(true);
      dispatch(goToNextQuestion(currentQuestion));
      setTimeout(() => setIsTransitioningQuestions(false), 100);
    }, 100);
  };

  const previousQuestions = () => {
    setFadeOut(true);
    setFadeIn(false);
    setTimeout(() => {
      setIsTransitioningQuestions(true);
      dispatch(goToPreviousQuestion(currentQuestion));
      setTimeout(() => setIsTransitioningQuestions(false), 100);
    }, 100);
  };

  const validateForm = () => {
    const regx = {
      birthdate: /^(19|20)\d\d+[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/,
      email: /^.+@[a-zA-Z_]+?\.[a-zA-Z]{2,10}$/,
      phone: /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
    };

    // General validation
    if (validate) {
      // const errorName = name || 'This field';
      const errorMsg = validate.message
        ? validate.message
        : name
        ? `${name} is required`
        : 'This field is required';
      if (
        validate.required &&
        formType === 'multi-select' &&
        !answers[currentQuestion].length
      ) {
        dispatch(
          addError({
            qId: currentQuestion,
            error: `At least one selection is required`,
          })
        );
        return false;
      }

      if (formType === 'name-input') {
        let isValid = true;
        if (validate.required) {
          // If there's no value for this key
          for (const answerKey in answers[currentQuestion]) {
            // Val for the error messafge
            const qName =
              answerKey === 'name_first'
                ? 'First name'
                : answerKey === 'name_last'
                ? 'Last name'
                : 'Name';

            // Dispatch error
            if (!answers[currentQuestion][answerKey]) {
              dispatch(
                addError({
                  qId: currentQuestion,
                  subId: answerKey,
                  error: `${qName} is required`,
                })
              );

              isValid = false;
            }
          }
        }

        // If something was invalid, return false
        if (!isValid) {
          return false;
        }
      }

      // Required
      if (validate.required && !answers[currentQuestion]) {
        dispatch(
          addError({
            qId: currentQuestion,
            error: errorMsg,
          })
        );
        return false;
      }

      // Length
      if (
        validate.length &&
        answers[currentQuestion].length !== validate.length
      ) {
        dispatch(
          addError({
            qId: currentQuestion,
            error: errorMsg,
          })
        );
        return false;
      }

      if (
        validate.regex &&
        answers[currentQuestion] &&
        !regx[validate.regex].test(answers[currentQuestion])
      ) {
        dispatch(
          addError({
            qId: currentQuestion,
            error: errorMsg,
          })
        );
        return false;
      }
    }

    // Subform validation
    if (formType === 'level-select') {
      let isValid = true;
      for (const option of options) {
        if (
          option.validate.required &&
          (!answers[currentQuestion] || !answers[currentQuestion][option.id])
        ) {
          const errMsg1 = option?.validate?.message
            ? option.validate.message
            : 'This field is required';

          dispatch(
            addError({
              qId: currentQuestion,
              subId: option.id,
              error: errMsg1,
            })
          );
          isValid = false;
        }
        if (
          option.validate.regex &&
          answers[currentQuestion][option.id] &&
          !regx[option.validate.regex].test(answers[currentQuestion][option.id])
        ) {
          const errMsg2 = option?.validate?.message
            ? option.validate.message
            : 'This field is incorrect';

          dispatch(
            addError({
              qId: currentQuestion,
              subId: option.id,
              error: errMsg2,
            })
          );
          isValid = false;
        }
      }
      // If something was invalid, return false
      if (!isValid) {
        return false;
      }
    }

    // Specialized "Add children" component
    if (formType === 'add-children') {
      let isValid = true;
      // Add-Children values are an array
      console.log(answers[currentQuestion]);
      for (let i = 0; i < answers[currentQuestion].length; i++) {
        console.log(answers[currentQuestion][i]);
        let j = 0;
        for (const answerKey in answers[currentQuestion][i]) {
          // If this config option requires validation, and the answer at the current key doesn't pass validation
          if (
            options[j]?.validate?.required &&
            options[j]?.validate?.regex &&
            !regx[options[j].validate.regex].test(
              answers[currentQuestion][i][answerKey]
            )
          ) {
            console.log('is an errpr');
            dispatch(
              addError({
                qId: currentQuestion,
                subId: answerKey,
                error: `Please enter a valid date of birth`,
              })
            );
            isValid = false;
          } else if (
            options[j]?.validate?.required &&
            options[j]?.validate?.message &&
            !answers[currentQuestion][i][answerKey]
          ) {
            dispatch(
              addError({
                qId: currentQuestion,
                subId: answerKey,
                error: options[j].validate.message,
              })
            );
          }

          j++;
        }
      }

      // If something was invalid, return false
      if (!isValid) {
        return false;
      }
    }

    // If none of that
    return true;
  };

  const handleSubmit = () => {
    const isValid = validateForm();
    if (isValid) {
      nextQuestion();
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !autoNext) {
      handleSubmit();
    }
  };

  const logEventToAmplitude = () => {
    const props = {
      number: currentQuestion,
      name,
    };
    amplitude.getInstance().logEvent('survey_step', props);
  };
  () => {
    window.scrollTo(0, 0);
    logEventToAmplitude();
  },
    [currentQuestion];

  useEffect(() => {
    if (fadeOut) {
      setTimeout(() => {
        setFadeIn(true);
        setFadeOut(false);
      }, 500);
    }
  }, [fadeOut]);

  useEffect(() => {
    // When the type is set, set the questions for this survey
    if (type && funnels[type]) {
      dispatch(setTotal(funnels[type].total));
      dispatch(setAllAnswers(funnels[type].answers));
      dispatch(setQuestions(funnels[type].questions));
      dispatch(setCurrentQuestion(1)); // TEMP
    }
  }, [type]);

  // Add a notification if needed
  useEffect(() => {
    let msg = '';
    // If there's a notification message to show
    if (
      notifications &&
      notifications.some(
        (notif) => notif.show_if_value_is === answers[notif.q_to_compare]
      )
    ) {
      msg = notifications.find(
        (notif) => notif.show_if_value_is === answers[notif.q_to_compare]
      ).message;
    }
    setNotificationMsg(msg);
  }, [notifications]);

  return (
    <section
      onKeyDown={handleKeyDown}
      role="presentation"
      className="bg-white max-w-5xl p-5 md:p-8 mx-auto w-full"
    >
      {total === 0 ? (
        <LoadingSkeleton />
      ) : (
        <>
          <div className="mb-4">
            <ProgressBar />
          </div>

          <div
            className={`${fadeIn ? 'opacity-100' : ''} ${
              fadeOut ? 'opacity-0' : ''
            } opacity-0 transition-all duration-100`}
          >
            <PreviousQuestion onClick={previousQuestions} />

            {notificationMsg && (
              <div className="w-full mt-2 md:mt-0 text-center">
                <Notification title={notificationMsg} />
              </div>
            )}

            <div className="my-4 sm:mt-10 sm:mb-7 text-lg">
              <span dangerouslySetInnerHTML={{ __html: label }} />
            </div>

            <div className={quoteItemsWrapperStyles}>
              {!isTransitioningQuestions && (
                <>
                  {formType === 'select' && (
                    <QuoteSelect
                      options={options}
                      otherOptions={otherOptions}
                      otherOptionsLabel={otherOptionsLabel}
                      autoNext={autoNext}
                      onSelect={handleOnChange}
                    />
                  )}

                  {formType === 'state-medicare-select' && (
                    <QuoteStateMedicareSelect
                      options={options}
                      otherOptions={otherOptions}
                      otherOptionsLabel={otherOptionsLabel}
                      autoNext={autoNext}
                      onSelect={handleOnChange}
                    />
                  )}

                  {formType === 'multi-select' && (
                    <QuoteMultiSelect
                      options={options}
                      otherOptions={otherOptions}
                      otherOptionsLabel={otherOptionsLabel}
                      autoNext={autoNext}
                      onSelect={handleOnChange}
                    />
                  )}

                  {formType === 'level-select' && (
                    <QuoteLevelSelect
                      options={options}
                      onSelect={handleOnChange}
                    />
                  )}

                  {formType === 'add-children' && (
                    <QuoteAddChildren
                      onSelect={handleOnChange}
                      doValidation={validateForm}
                      options={options}
                    />
                  )}

                  {formType === 'vehicle-model' && (
                    <QuoteVehicleModel name={name} onSelect={handleOnChange} />
                  )}

                  {formType === 'address' && (
                    <QuoteAddress onSelect={handleOnChange} />
                  )}

                  {[
                    'birthday',
                    'email',
                    'text',
                    'post-code',
                    'zip-code',
                    'phone',
                  ].includes(formType) && (
                    <QuoteFormItem
                      label={itemLabel}
                      formType={formType}
                      onChange={handleOnChange}
                      placeholder={placeholder}
                    />
                  )}
                </>
              )}
            </div>
            {total === currentQuestion && type === 'medicare' && (
              <p className="text-xs">
                By clicking the View My Results Button, I agree to the consents
                below the&nbsp;button.
              </p>
            )}

            {!autoNext && (
              <section className="flex justify-start mt-6">
                <FormButton onClick={handleSubmit} label={btnLabel} />
              </section>
            )}

            {total === currentQuestion && <TermsAndConditions type={type} />}
          </div>
        </>
      )}
    </section>
  );
};

QuoteForm.propTypes = {
  onShowQuotes: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default QuoteForm;
