import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeError } from '@/store/slices/formSlice';
import PropTypes from 'prop-types';
import Button from '@/components/Button';

const FormCheckbox = ({
  value,
  valOne,
  valTwo,
  labelOne,
  labelTwo,
  name = '',
  title = '',
  onChange,
  disabled = false,
  subId = null,
}) => {
  const dispatch = useDispatch();

  const { currentQuestion, errors } = useSelector((store) => store.form);

  const error =
    subId && errors[currentQuestion]
      ? errors[currentQuestion][subId]
      : errors[currentQuestion];

  const onChangeHandler = (val) => {
    const errorUpdate = { qId: currentQuestion };
    if (subId) {
      errorUpdate['subId'] = subId;
    }
    if (val && error) {
      dispatch(removeError(errorUpdate));
    }
    onChange(val);
  };

  return (
    <div className="">
      <p className="text-sm text-gray-800 mb-2">{title}</p>
      <div className="flex flex-row pt-1.5">
        <div className="mr-2">
          <Button
            onClick={() => onChangeHandler(valOne)}
            variant="secondary"
            size="md"
            active={value === valOne}
          >
            {labelOne}
          </Button>
        </div>
        <div>
          <Button
            onClick={() => onChangeHandler(valTwo)}
            variant="secondary"
            size="md"
            active={value === valTwo}
          >
            {labelTwo}
          </Button>
        </div>
      </div>
      {error && <div className="mt-1 text-red text-sm">{error}</div>}
    </div>
  );
};

FormCheckbox.propTypes = {
  value: PropTypes.string.isRequired,
  valOne: PropTypes.string.isRequired,
  valTwo: PropTypes.string.isRequired,
  labelOne: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  labelTwo: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  name: PropTypes.string,
  title: PropTypes.string,
  subId: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default FormCheckbox;
