import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/solid';

const PromptIcon = ({ icon = 'success', className = '' }) => {
  const colours = {
    warning: 'text-yellow-400',
    success: 'text-green-400',
    info: 'text-blue-400',
    danger: 'text-red-400',
  };

  const iconClass = `${className} ${colours[icon]}`;

  return (
    <>
      {icon === 'success' && <CheckCircleIcon className={iconClass} />}
      {icon === 'warning' && <ExclamationIcon className={iconClass} />}
      {icon === 'danger' && <XCircleIcon className={iconClass} />}
      {icon === 'info' && <InformationCircleIcon className={iconClass} />}
    </>
  );
};

PromptIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default PromptIcon;
