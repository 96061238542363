import React from 'react';
import PropTypes from 'prop-types';
import PromptIcon from '@/components/PromptIcon';

const Alert = ({ title = '', type = 'success', message = '' }) => {
  const bgColours = {
    warning: 'bg-yellow-50',
    success: 'bg-green-50',
    info: 'bg-blue-50',
    danger: 'bg-red-50',
  };

  const titleColours = {
    warning: 'text-yellow-800',
    success: 'text-green-800',
    info: 'text-blue-800',
    danger: 'text-red-800',
  };

  const textColours = {
    warning: 'text-yellow-700',
    success: 'text-green-700',
    info: 'text-blue-700',
    danger: 'text-red-700',
  };

  return (
    <div
      className={`rounded-md ${bgColours[type]} p-2 md:p-3 inline-block w-full md:w-auto text-left md:text-center`}
    >
      <div className="flex justify-center">
        <div className="flex-shrink-0">
          <PromptIcon icon={type} className="h-5 w-5" />
        </div>
        <div className="ml-3">
          {title && (
            <h3 className={`text-sm font-medium ${titleColours[type]}`}>
              {title}
            </h3>
          )}
          {message && (
            <div className={`mt-2 text-sm ${textColours[type]}`}>{message}</div>
          )}
        </div>
      </div>
    </div>
  );
};

Alert.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  message: PropTypes.string,
};

export default Alert;
