import React from 'react';
import Logo from '@/components/Logo';
import { css } from '@emotion/css';

const AppHeader = () => {
  const logoWrapperStyles = css`
    max-width: 1800px;

    svg {
      height: 51px;
    }

    @media only screen and (min-width: 1200px) {
      max-width: 210px;
      svg {
        height: 60px;
      }
    }
  `;

  return (
    <header className="bg-electricBlue p-1 pt-1.5 md:p-2">
      <section className="text-white md:h-13.5 max-w-5xl mx-auto flex md:flex-row flex-col items-center justify-start">
        <div className={logoWrapperStyles}>
          <Logo />
        </div>
        <div className="text-sm md:flex hidden ml-4 pl-3  text-left  border-l border-electricBlue-400 h-8  items-center">
          Compare insurance quotes from top insurance providers with one simple
          form!
        </div>
      </section>
    </header>
  );
};

export default AppHeader;
