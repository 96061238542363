import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeError } from '@/store/slices/formSlice';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

const QuoteFormItem = ({
  formType,
  name,
  placeholder = '',
  onChange,
  label = '',
  subId = null,
  defaultVal = '',
}) => {
  const type = ['birthday', 'phone', 'zip-code'].includes(formType)
    ? 'tel'
    : 'text';
  const dispatch = useDispatch();
  const hasMask = ['birthday', 'phone', 'post-code', 'zip-code'].includes(
    formType
  );

  const [inputValue, setInputValue] = useState('');

  const { currentQuestion, answers, errors } = useSelector(
    (store) => store.form
  );

  const error =
    subId && errors[currentQuestion]
      ? errors[currentQuestion][subId]
      : errors[currentQuestion];

  const inputStyle = `${
    error ? 'border-red bg-red-100' : 'border-gray bg-gray-200'
  } border rounded  p-3 sm:min-w-xxs  sm:max-w-xxs transition-colors text-blue focus:border-blue duration-200`;

  const getDefault = () => {
    if (defaultVal) return defaultVal;

    return subId && answers[currentQuestion]
      ? answers[currentQuestion][subId]
      : answers[currentQuestion];
  };

  const setMask = () => {
    if (formType === 'post-code') {
      const firstLetter = /(?!.*[DFIOQU])[A-VXY]/i;
      const letter = /(?!.*[DFIOQU])[A-Z]/i;
      const digit = /[0-9]/;
      return [firstLetter, digit, letter, ' ', digit, letter, digit];
    }

    if (formType === 'zip-code') {
      return '99999';
    }

    if (formType === 'birthday') {
      return '9999/99/99';
    }

    if (formType === 'phone') {
      return '(999) 999-9999';
    }
  };

  const handleOnChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    const errorUpdate = { qId: currentQuestion };
    if (subId) {
      errorUpdate['subId'] = subId;
    }

    if (value && error) {
      dispatch(removeError(errorUpdate));
    }
    onChange(value);
  };

  // If a default value was passed in, use it
  useEffect(() => {
    if (getDefault()) {
      setInputValue(getDefault());
    }
  }, []);

  return (
    <div className="mb-4 flex flex-col">
      {label && (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label
          className="text-sm text-gray-800 mb-2"
          dangerouslySetInnerHTML={{ __html: label }}
          htmlFor={formType}
        />
      )}

      {hasMask ? (
        <InputMask
          type={type}
          name={name}
          mask={setMask()}
          placeholder={placeholder}
          onChange={handleOnChange}
          defaultValue={inputValue}
          maskPlaceholder={null}
          className={inputStyle}
          value={inputValue}
        />
      ) : (
        <input
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={handleOnChange}
          className={inputStyle}
          value={inputValue}
        />
      )}
      {error && <div className="mt-1 text-red text-sm">{error}</div>}
    </div>
  );
};

QuoteFormItem.propTypes = {
  formType: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  subId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultVal: PropTypes.string,
};

export default QuoteFormItem;
