import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const ProgressBar = () => {
  const { currentQuestion, total } = useSelector((store) => store.form);
  const [progress, setProgress] = useState(5);

  useEffect(() => {
    const currentProgress =
      currentQuestion === 1 ? 5 : Math.round((currentQuestion / total) * 100);
    setProgress(currentProgress);
  }, [currentQuestion, total]);

  return (
    <div className="relative">
      <div
        className="h-3 z-10 top-0 left-0 absolute transition-all duration-300 rounded-lg bg-green"
        style={{ width: `${progress}%` }}
      />
      <div className="w-full absolute h-3 bg-gray-200 border border-gray rounded-lg">
        {/* {progress}% */}
      </div>
    </div>
  );
};

export default ProgressBar;
