import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { randId } from '@/utils/helpers';

const FormCheckbox = ({
  checked = false,
  name = '',
  label = '',
  onChange,
  disabled = false,
  id,
}) => {
  const [itemId] = useState(id || randId());

  return (
    <div className="flex items-center">
      <input
        id={itemId}
        disabled={disabled}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
      />
      <label htmlFor={itemId} className="ml-2 block text-sm text-gray-900">
        {label}
      </label>
    </div>
  );
};

FormCheckbox.propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default FormCheckbox;
