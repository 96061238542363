import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { removeError } from '@/store/slices/formSlice';
import { useSelector, useDispatch } from 'react-redux';
import FormCheckbox from '@/components/FormCheckbox';
import QuoteFormSelect from './QuoteFormSelect';

const QuoteSelect = ({ options, onSelect, subId = false }) => {
  const dispatch = useDispatch();
  const { currentQuestion, answers, errors } = useSelector(
    (store) => store.form
  );

  const error =
    subId && errors[currentQuestion]
      ? errors[currentQuestion][subId]
      : errors[currentQuestion];

  const handleOnSelect = (option) => {
    if (option && error) {
      dispatch(removeError({ qId: currentQuestion, subId }));
    }

    // If the answer is already in the answers array
    if (answers[currentQuestion].includes(option)) {
      onSelect(answers[currentQuestion].filter((val) => val !== option));
      return;
    }

    // Otherwise, add it
    onSelect([...answers[currentQuestion], option]);
  };

  // If a value isn't initially set for this question, set it as empty array
  useEffect(() => {
    if (!answers[currentQuestion]) {
      onSelect([]);
    }
  }, []);

  return (
    <>
      <div className="">
        {options.map((option, i) => (
          <div key={`opt-${i}`} className="mx-3 my-4 sm:m-2">
            <FormCheckbox
              label={option.label}
              onChange={() => handleOnSelect(option.name)}
              checked={
                answers[currentQuestion] &&
                answers[currentQuestion].includes(option.name)
              }
            />
          </div>
        ))}
      </div>
      {error && <div className="mt-1 text-red text-sm">{error}</div>}
    </>
  );
};

QuoteSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  subId: PropTypes.number,
};

export default QuoteSelect;
