import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { removeError } from '@/store/slices/formSlice';
import { useSelector, useDispatch } from 'react-redux';
import QuoteChild from './QuoteChild';
import { randId } from '@/utils/helpers';
import Button from '@/components/Button';

const QuoteAddChildren = ({
  onSelect,
  subId = false,
  options = [],
  doValidation,
}) => {
  const dispatch = useDispatch();
  const { currentQuestion, answers, errors } = useSelector(
    (store) => store.form
  );

  const [childrenState, setChildrenState] = useState([
    {
      '1_dob': '',
      '1_gender': '',
      '1_is_smoker': false,
    },
  ]);
  const [isValid, setIsValid] = useState(false);

  const addChildHandler = (e) => {
    // if (isValid) {
    setChildrenState((prevState) => {
      const newPos = prevState.length + 1;
      return [
        ...prevState,
        {
          [newPos + '_dob']: '',
          [newPos + '_gender']: '',
          [newPos + '_is_smoker']: false,
        },
      ];
    });
    // }
  };

  const childChangeHandler = (newVal, id, index) => {
    /*if (error?.[id]) {
      dispatch(removeError({ qId: currentQuestion, subId: id }));
    }*/

    setChildrenState((prevState) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [id]: newVal,
      };
      return newState;
    });
  };

  const error =
    subId && errors[currentQuestion]
      ? errors[currentQuestion][subId]
      : errors[currentQuestion];

  useEffect(() => {
    onSelect(childrenState);
  }, [childrenState]);

  // If a value isn't initially set for this question, set the default values
  useEffect(() => {
    if (!answers[currentQuestion]) {
      onSelect([
        {
          '1_dob': '',
          '1_gender': '',
          '1_is_smoker': false,
        },
      ]);
    }
  }, []);

  return (
    <div>
      <div className="flex flex-col">
        {childrenState.map((child, index) => (
          <QuoteChild
            onChange={(val, id) => childChangeHandler(val, id, index)}
            value={child}
            key={'add-child-' + index}
            options={options}
            answer={child}
          />
        ))}
      </div>
      <Button onClick={addChildHandler} variant="secondary" disabled={false}>
        +Add Child
      </Button>
    </div>
  );
};

QuoteAddChildren.propTypes = {
  onSelect: PropTypes.func.isRequired,
  subId: PropTypes.number,
  doValidation: PropTypes.func,
  options: PropTypes.array,
};

export default QuoteAddChildren;
