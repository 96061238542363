import React from 'react';

const LoadingSkeleton = () => (
  <div className="mt-8">
    <div className="animate-pulse bg-gray-300 w-1/3 h-6 rounded-lg mt-4 mb-8" />
    <div className="animate-pulse bg-gray-300 w-2/3 h-6 rounded-lg" />
    <div className="animate-pulse bg-gray-300 w-full h-10 rounded-lg mt-4" />
    <div className="animate-pulse bg-gray-300 w-full h-10 rounded-lg mt-4" />
    <div className="animate-pulse bg-gray-300 w-full h-10 rounded-lg mt-4" />
  </div>
);

export default LoadingSkeleton;
