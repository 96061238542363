import React from 'react';
import PropTypes from 'prop-types';

const VARIANT_MAP = {
  primary: 'text-white hover:bg-indigo-700 focus:ring-indigo-500',
  secondary: 'text-indigo-700 hover:bg-indigo-200 focus:ring-indigo-500',
};

const INACTIVE_MAP = {
  primary: 'bg-indigo-600',
  secondary: 'bg-indigo-100',
};

const ACTIVE_MAP = {
  primary: 'bg-indigo-700 ring-indigo-500 ring-2 ring-offset-2',
  secondary: 'bg-indigo-200 ring-indigo-500 ring-2 ring-offset-2',
};

const PADDING_MAP = {
  xs: 'px-2.5 py-1.5',
  sm: 'px-3 py-2',
  md: 'px-4 py-2',
  lg: 'px-4 py-2',
  xl: 'px-6 py-3',
};

const SIZES_MAP = {
  xs: 'text-xs font-medium rounded shadow-sm',
  sm: 'text-sm leading-4 font-medium rounded-md shadow-sm',
  md: 'text-sm font-medium rounded-md shadow-sm',
  lg: 'border border-transparent text-base font-medium rounded-md shadow-sm',
  xl: 'border border-transparent text-base font-medium rounded-md shadow-sm',
};

const ICON_SIZES_MAP = {
  xs: '-ml-0.5 mr-2 h-4 w-4',
  sm: '-ml-1 mr-2 h-5 w-5',
  md: '-ml-1 mr-3 h-5 w-5',
  lg: '-ml-1 mr-3 h-5 w-5',
  xl: '-ml-1 mr-3 h-5 w-5',
};

const disabledStyles = 'opacity-60 cursor-default';

const Button = ({
  children,
  onClick,
  variant = 'primary',
  size = 'md',
  Icon = null,
  active = false,
  disabled = false,
  round = false,
  className = '',
}) => {
  return (
    <button
      type="button"
      className={`
        inline-flex 
        items-center 
        border border-transparent 
        focus:outline-none 
        focus:ring-2 
        focus:ring-offset-2 
        transition-colors 
        duration-100 
        ${VARIANT_MAP[variant]} 
        ${SIZES_MAP[size]}
        ${!round && PADDING_MAP[size]} 
        ${active ? ACTIVE_MAP[variant] : INACTIVE_MAP[variant]} 
        ${disabled ? disabledStyles : ''}
        ${className}
      `}
      onClick={onClick}
      disabled={disabled}
    >
      {Icon && (
        <span className={ICON_SIZES_MAP[size]}>
          <Icon />
        </span>
      )}
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string,
  size: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  Icon: PropTypes.any,
  className: PropTypes.string,
  round: PropTypes.bool,
};

export default Button;
