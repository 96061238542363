import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import QuoteForm from '@/components/QuoteForm';
import YourQuotes from '@/components/YourQuotes';
import CompareRatesFrom from '@/components/CompareRatesFrom';
import { mapAnswers } from '@/utils/helpers';
import axios from '@/config/axios';
import amplitude from 'amplitude-js';

const AppSurvey = ({ loading = true, onError, urlParams }) => {
  const { questions, answers } = useSelector((store) => store.form);
  const [showQuotes, setShowQuotes] = useState(false);
  const [loadingRates, setLoadingRates] = useState(true);
  const [quotes, setQuotes] = useState([]);
  const [buyerId, setBuyerId] = useState('');
  const [surveyType, setSurveyType] = useState('');

  const submitForm = async () => {
    setShowQuotes(true);

    const params = {
      ...urlParams,
      payload: mapAnswers(questions, answers),
    };

    console.log('Final submission:');
    console.log(params);

    // TEMP, un-comment later
    /*
    try {
      const { data } = await axios().post('survey/submit', params);

      if (!!data.result?.redirect_url) {
        window.location.href = data.result.redirect_url;
        return;
      }

      setQuotes(data.result);
      setLoadingRates(false);
    } catch (err) {
      amplitude.getInstance().logEvent('error_frontend', {
        endpoint: 'survey/submit',
        error: err.response.data,
      });
      onError();
    }
    */
  };

  const sendClick = async () => {
    // TEMP
    setSurveyType('medicare');
    return;
    // TEMP
    /*
    try {
      const { data } = await axios().post('survey/start', urlParams);

      if (data?.result?.buyer_id) {
        setBuyerId(data.result.buyer_id);
      }

      if (data?.result?.type) {
        setSurveyType(data.result.type);
      }
    } catch (err) {
      amplitude.getInstance().logEvent('error_frontend', {
        endpoint: 'survey/start',
        error: err.response.data,
      });
    }
    */
  };

  useEffect(() => {
    if (Object.keys(urlParams).length) {
      sendClick();
    }
  }, [urlParams]);

  return (
    <div className="flex-grow">
      {showQuotes ? (
        <YourQuotes quotes={quotes} loading={loadingRates} />
      ) : (
        <>
          {!loading && (
            <QuoteForm onShowQuotes={submitForm} type={surveyType} />
          )}
        </>
      )}
    </div>
  );
};

AppSurvey.propTypes = {
  loading: PropTypes.bool,
  urlParams: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AppSurvey;
