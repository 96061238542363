import PropTypes from 'prop-types';
import Alert from '@/components/Alert';

const SurveyError = ({ unableShowQuotes = false }) => {
  const errorMsg = `${
    unableShowQuotes
      ? 'We were unable to load your rates'
      : 'Something went wrong'
  }. Please reload the page or try again later.`;

  return (
    <div className="m-8">
      <Alert>{errorMsg}</Alert>
    </div>
  );
};

SurveyError.propTypes = {
  unableShowQuotes: PropTypes.bool,
};

export default SurveyError;
