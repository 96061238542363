import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import axios from '@/config/axios';
import QuoteSelect from './QuoteSelect';
import { useSelector } from 'react-redux';
import { data } from 'autoprefixer';
import QuoteButton from './QuoteButton';
import cars from '@/data/cars.json';

const QuoteVehicleModel = ({ name, onSelect }) => {
  const [loading, setLoading] = useState(true);
  const [models, setModels] = useState([]);
  const { answers } = useSelector((state) => state.form);

  const getOptions = async () => {
    const { year, make } = getCurrentMakeYear();
    const modelsList = cars?.[make]?.[year];
    if (make === 'OTHER MAKE' || !modelsList) {
      onSelect('');
      return;
    }

    setLoading(false);

    setModels(modelsList);
  };

  const getCurrentMakeYear = () => {
    if (name === 'vehicle_2_model') {
      return {
        year: answers[6],
        make: answers[7].toUpperCase(),
      };
    }
    return {
      year: answers[2],
      make: answers[3].toUpperCase(),
    };
  };

  useEffect(() => {
    getOptions();
  }, []);

  if (loading) return <></>;

  return <QuoteSelect options={models} onSelect={onSelect} />;
};

QuoteVehicleModel.propTypes = {
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
};

export default QuoteVehicleModel;
