import React from 'react';
import PropTypes from 'prop-types';

const TermsAndConditions = ({ type = '' }) => {
  if (!type) return <></>;

  return (
    <>
      {type === 'medicare' && (
        <div className="w-full mt-2">
          <div className="tcpa-disclosure text-xs">
            <b className="mt-3 inline-block">Consent to Be Contacted</b>. I
            agree to be contacted by select insurance carriers and financial
            institutions listed{' '}
            <a
              className="underline"
              href="/tcpa-partner-companies"
              target="_blank"
            >
              here
            </a>
            , their agents, individual insurance agents, and/or Assurance for
            marketing purposes concerning insurance and/or other financial
            products by phone/text at my number provided above (including by
            autodialer, prerecorded message and/or artificial voice), even if my
            number is on a do not call list, or by email at the email address I
            have provided. Texts about these offers may be sent from Assurance’s
            Shopper Alerts number, 71953 (message &amp; data rates may apply).
            Consent is not required to make a purchase and I can opt out any
            time.
            <br />
            <span className="prudential-medicare--space-bottom"></span>{' '}
            <b className="mt-3 inline-block">Consent to Share Information</b>. I
            agree to Assurance sharing my information with Prudential companies
            and affiliates so that they can market their products and services
            to me, and to Assurance sharing my information with third-party
            partners so that select insurers and financial institutions, and
            their agents, may make insurance, credit and other financial offers
            to me. I agree to Assurance’s{' '}
            <a className="underline" href="/privacy-policy" target="_blank">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              className="underline"
              href="/terms-and-conditions"
              target="_blank"
            >
              Terms of Service
            </a>
            .<br />
            <span className="prudential-medicare--space-bottom"></span>{' '}
            <b className="mt-3 inline-block">Medicare</b>. If I am
            Medicare-eligible, I am requesting to speak with a licensed agent
            who is certified to discuss Medicare Advantage and Prescription Drug
            insurance plans. This will NOT obligate me to enroll in a plan,
            affect my current enrollment, or enroll me in a Medicare plan.
            <br />
          </div>
        </div>
      )}
    </>
  );
};

TermsAndConditions.propTypes = {
  type: PropTypes.string,
};

export default TermsAndConditions;
