import React from 'react';

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="196"
    height="54"
    viewBox="0 0 932.75 261.85"
    className="w-full"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M150,37.37H134.56c-1.3-15.19-4.38-28.31-9.15-37.37a117,117,0,0,1-6.12,37.37H0v187.1H23.34a260.93,260.93,0,0,0-8.61,37.38l8.83-7.52A127.05,127.05,0,0,1,28,224.47H150Zm-26.34-.19a1.23,1.23,0,0,1,0,.19h0ZM4.87,219.59V42.24H117.54c-9.13,23.84-24.9,42.94-40.05,58.22-3,15.6-9.92,24.81-9.92,24.81a63.87,63.87,0,0,0,5.77-20.71c-5.92,5.77-11.65,11-16.72,15.63-7.73,9.16-16.82,23.55-18,41.52,0,0-1.64-12,3.9-27.65-.5.6-1,1.18-1.44,1.76-8.93,11.48-10.76,24.45-10.5,34.12-7.66-6.76-14.84-7.62-14.84-7.62,11,4.88,15.46,17.11,16.62,21,.22.78.35,1.21.35,1.21C57.78,138.18,107,115,122.64,42.24H123c-12.89,88.68-70.43,96.35-98,177.35Zm140.26,0H29.42c5.43-16.66,12.65-25,12.65-25C62.58,205,78.4,199.82,78.4,199.82A41.85,41.85,0,0,1,59,195.34c16.17-1.55,29.58-9.86,40.42-22.34-19,10.88-35.73,9.53-35.73,9.53,27.39-3.34,45.38-24.7,49.85-30.58a181,181,0,0,0,15-41.2c-13.57,5.85-23.06,7.19-23.06,7.19a115.78,115.78,0,0,0,24.16-12,240.09,240.09,0,0,0,5.27-63.73h10.21Z"
    />
    <g>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M303.28,94.41c-7.51,1.25-9.45,2.73-12.52,12.28-2.62,7.28-9.33,27.08-18.88,58.94H266c-6.14-17-12.17-33.23-18.55-50.52h-.22c-5.92,17.18-11.72,34.13-17.07,50.52h-6c-6.14-20.48-11.83-39.6-18.2-59.73-2.73-8.88-4.67-10.47-11.38-11.49v-4h33.68v4c-8.65,1-8.76,2.61-7.17,8.42,3.75,13.31,7.62,26.5,11.6,39.13h.23c5.35-16.15,11-33.33,16.38-50.85h5.47c6.37,17.29,12.85,34.7,19.11,51.31H274c4.43-14,9.1-31.74,10.69-38.34,1.59-6.83,1-8.76-8.19-9.67v-4h26.74Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M334,165.74a10.14,10.14,0,0,1-6.94-2.39,10.59,10.59,0,0,1-3-4.21c-4.32,2.85-9.67,6.6-11.83,6.6-9.67,0-15.36-7.51-15.36-14.56,0-5.92,2.73-8.76,8.65-10.92,6.71-2.51,15.47-5.24,18.09-7.51v-3.42c0-7.16-2.73-11.15-6.94-11.15a4.59,4.59,0,0,0-3.76,2c-1.36,1.59-2.38,4.33-3.29,7.85-.8,3-2.39,3.87-4.78,3.87-3,0-7.06-2.73-7.06-5.69,0-2.05,1.37-3.52,4-5.46,4.32-3,12.4-6.94,18.88-8.08,4.67,0,8.08,1,11.15,3.3,4.89,3.76,5.92,8.65,5.92,15v18.77c0,5.24,1.82,6.71,3.64,6.71a11.28,11.28,0,0,0,3.87-.91l1.13,3.64Zm-10.35-28.56c-2.05,1-4,2.28-6.26,3.53-3.52,1.82-5.35,3.87-5.35,7.74,0,6.37,4.21,8.76,6.83,8.76a7.7,7.7,0,0,0,4.78-1.82Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M400,117.73c-5.46.91-6.6,2-9.44,8.19s-6.6,15.47-13.2,31.29c-7.62,18.43-9.9,25.48-11.95,31.85a3.84,3.84,0,0,1-4.21,3.07c-3.07,0-8-3.07-8-7.16,0-1.82.8-3,3.07-4.44,4.55-2.39,7.17-4.55,9.33-8.08a37.49,37.49,0,0,0,2.16-4.66,8,8,0,0,0-.45-5.35c-5.92-16.5-12.18-32.65-14.34-37.66s-3.41-6.25-8.76-7v-3.64h28.22v3.64c-4.9.91-5.81,1.59-4.55,5.23l8.53,25.26c2.61-6.37,6.48-17.75,8.53-24.23,1.25-3.76.11-5.24-5.46-6.26v-3.64H400Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M400.09,164.37v-3.64c6.71-1,7.39-1.59,7.39-8.87V99.75c0-6.82-.56-7.16-6.82-8v-3.3a153.17,153.17,0,0,0,21.27-4.1v67.47c0,7.62.69,8.08,7.63,8.87v3.64Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M478.7,153.11c-6.94,10.35-15.81,12.63-20.47,12.63-15.14,0-23.55-11.26-23.55-24.35a28.87,28.87,0,0,1,8.41-20.59c5-5,11.27-8.08,17.52-8.08A18.63,18.63,0,0,1,479.05,131c0,2.28-.69,3.75-3.3,4.1-2.05.22-15.93,1.36-27.08,1.82.57,12.17,7.28,18.31,15.36,18.31a18.9,18.9,0,0,0,12.63-4.89Zm-29.46-21.5,13.54-.34c1.7-.12,2.27-.34,2.39-2.28,0-5.8-2.51-11.72-6.94-11.72S449.92,122.51,449.24,131.61Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M516,128.76c-3.07-6.94-6.71-11.6-12.06-11.6a6,6,0,0,0-5.8,6.14c0,3.53,2.61,6.15,8.42,8.76,9.21,4,15.36,8,15.36,16.84,0,11.26-10.81,16.84-20.37,16.84-6,0-12.06-2.16-14.33-3.87-.69-1.82-1.71-9.78-1.82-15.24l3.41-.35c2.5,7.17,7.74,15,14.67,15a6,6,0,0,0,6.26-6.14c0-3.3-1.59-6.14-8.64-9.44-5.24-2.62-14.45-7.06-14.45-16.84,0-8.88,7.17-16.16,19.11-16.16a25.32,25.32,0,0,1,10.92,2c1.14,2.62,2.51,10.7,2.73,13Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M559.59,128.76c-3.07-6.94-6.71-11.6-12.06-11.6a6,6,0,0,0-5.8,6.14c0,3.53,2.62,6.15,8.42,8.76,9.22,4,15.36,8,15.36,16.84,0,11.26-10.81,16.84-20.37,16.84-6,0-12.06-2.16-14.33-3.87-.68-1.82-1.71-9.78-1.82-15.24l3.41-.35c2.5,7.17,7.74,15,14.68,15a6,6,0,0,0,6.26-6.14c0-3.3-1.6-6.14-8.65-9.44-5.23-2.62-14.45-7.06-14.45-16.84,0-8.88,7.17-16.16,19.11-16.16a25.37,25.37,0,0,1,10.93,2c1.13,2.62,2.5,10.7,2.73,13Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M675.41,183a44.53,44.53,0,0,1-16.73-.91c-3.64-.91-12.4-4.78-26.84-16.27-7.06-.45-17.75-4.43-24.12-11.37a38.78,38.78,0,0,1-10.13-26.4c0-20.14,13.65-39.36,39.93-39.36,20.71,0,37.66,15.36,37.66,37.77,0,19-11.49,32.76-25.14,37,7.28,5.12,18.2,12.29,26.39,16.5Zm-32.54-25c1,.69,2,1.48,3,2.16,5.23-2.16,11.94-11.6,11.94-30.26,0-22.64-9.1-36.4-22.64-36.4-12.4,0-20.13,14.1-20.13,32s6.71,32.08,18.54,35.49Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M741.28,161.64c-6.71.91-13.54,2.39-20.82,4.1l-.91-.8v-6.82a46.92,46.92,0,0,1-5.69,4.21c-3.3,2.16-6.37,3.41-9.9,3.41-9.55,0-14.9-6.14-14.9-16.16V126c0-5.46-.46-5.91-6.26-7.39v-3.3c6.94-.34,13.88-1,21.05-2-.23,4.78-.34,10.58-.34,17.18V147c0,7.39,3.87,10,7.85,10a10.74,10.74,0,0,0,8.19-3.19V126.15c0-5.8-.34-6.26-7.28-7.51v-3.3a210.89,210.89,0,0,0,21.73-2v37c0,6,.68,7,4.78,7.51l2.5.22Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M799.87,138.32c0,18.32-13.08,27.42-26.4,27.42-17.86,0-26.62-13-26.62-25.37,0-18.32,14.45-27.65,27.08-27.65C789.86,112.72,799.87,124.67,799.87,138.32Zm-36.75-2c0,14,4.44,25,11.61,25,4.32,0,8.87-2.84,8.87-19.57,0-15.81-4-24.57-11.15-24.57C767.67,117.16,763.12,123.19,763.12,136.27Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M830.47,164.49a17,17,0,0,1-4.66,1.25c-8.76,0-13.88-4.1-13.88-14.34V119.55h-7.17l-.57-1.37,3.19-4.09h4.55v-8.31l12.85-8.87,1.6.68v16.5h11.15c1.36,1.25,1.13,4.55-.69,5.46H826.38v28.1c0,8.87,4.09,9.9,6.48,9.9a13,13,0,0,0,5.69-1.37l1.14,4Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M886.9,153.11c-6.94,10.35-15.81,12.63-20.48,12.63-15.13,0-23.55-11.26-23.55-24.35a28.84,28.84,0,0,1,8.42-20.59c5-5,11.26-8.08,17.52-8.08,10.47,0,18.55,9,18.43,18.32,0,2.28-.68,3.75-3.3,4.1-2.05.22-15.92,1.36-27.07,1.82.56,12.17,7.28,18.31,15.35,18.31a18.87,18.87,0,0,0,12.63-4.89Zm-29.47-21.5,13.54-.34c1.71-.12,2.28-.34,2.39-2.28,0-5.8-2.5-11.72-6.94-11.72S858.12,122.51,857.43,131.61Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M924.22,128.76c-3.08-6.94-6.72-11.6-12.06-11.6a6,6,0,0,0-5.8,6.14c0,3.53,2.61,6.15,8.41,8.76,9.22,4,15.36,8,15.36,16.84,0,11.26-10.81,16.84-20.36,16.84-6,0-12.06-2.16-14.34-3.87-.68-1.82-1.7-9.78-1.82-15.24l3.42-.35c2.5,7.17,7.73,15,14.67,15a6,6,0,0,0,6.26-6.14c0-3.3-1.59-6.14-8.65-9.44-5.23-2.62-14.45-7.06-14.45-16.84,0-8.88,7.17-16.16,19.12-16.16a25.35,25.35,0,0,1,10.92,2c1.14,2.62,2.5,10.7,2.73,13Z"
      />
    </g>
  </svg>
);

export default Logo;
