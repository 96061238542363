const shouldQuestionBeSkipped = (allAnswers, skipItem) => {
  // Answers == all answers
  // SkipItem == Skip obj to use
  for (const key in skipItem) {
    // Guard clause: If this key isn't in the answers, continue loop
    if (!(key in allAnswers)) {
      continue;
    }

    // If this item is an array, and the array includes the answer of the test question
    if (
      Array.isArray(skipItem[key]) &&
      skipItem[key].includes(allAnswers[key])
    ) {
      // since the array contains the answer in the test question, skip this one
      return true;
    }

    // Since it's not an array, if the value matches, return true
    if (skipItem[key] === allAnswers[key]) {
      return true;
    }
  }

  return false;
};

export const getNextQuestion = (currentQuestion, questions, answers, total) => {
  let next = 1;

  for (let i = currentQuestion + 1; i <= total; i++) {
    const nextQ = questions[`q${i}`];
    if (
      nextQ &&
      nextQ.skipIf &&
      shouldQuestionBeSkipped(answers, nextQ.skipIf)
    ) {
      next++;
    } else {
      break;
    }
  }
  return next;
};

export const getPreviousQuestion = (currentQuestion, questions, answers) => {
  let previous = 1;
  for (let i = currentQuestion - 1; i >= 1; i--) {
    const prevQ = questions[`q${i}`];

    if (
      prevQ &&
      prevQ.skipIf &&
      shouldQuestionBeSkipped(answers, prevQ.skipIf)
    ) {
      previous++;
      /*
      const skipKey = Object.keys(prevQ.skipIf)[0];
      if (skipKey in answers && answers[skipKey] === prevQ.skipIf[skipKey]) {
        previous++;
      }
      */
    } else if ([''].includes(answers[i])) {
      previous++;
    } else {
      break;
    }
  }
  return previous;
};

const valuesFormatter = (value, type = null) => {
  // Guard
  if (!type) return value;

  if (type === 'dob') {
    // Replace / with -
    return value.replaceAll('/', '-');
  }

  if (type === 'phone') {
    // Remove any non-numbers
    return value.replace(/\D/g, '');
  }

  return value;
};

export const mapAnswers = (questions, answers) => {
  const payload = {};
  for (const answerId of Object.keys(answers)) {
    const q = questions[`q${answerId}`];
    if (q?.forSubmit?.omit) {
      // If this question isn't to be included in the payload. Likely, it was a selector for the following question.
      continue;
    }

    // If this property has a group of values. Only works to output objects
    if (q?.forSubmit?.group_as) {
      payload[q.forSubmit.group_as] = {
        ...payload[q.forSubmit.group_as],
        [q.type]: valuesFormatter(answers[answerId], q.type),
      };
      continue;
    }

    // If the values are in an array
    if (Array.isArray(answers[answerId]) && questions[`q${answerId}`].options) {
      answers[answerId].forEach((answer, index) => {
        // If it's an array, but each result is supposed to be 'flattened' in the final submission (e.g., child_1_name = 'Bob')
        if (answer && q.forSubmit?.flatten) {
          const answerName2 = `${q.name}_${answer}`;
          payload[answerName2] = true;
          return;
        }

        // If it's an array, there are options for this question, and the options use a 'type', use the option type as the key
        if (answer && q?.options[index]?.type) {
          // Use the existing values, or make a new array
          payload[q.name] = payload[q.name] || [];
          // Counter used to loop through options[] to get type value
          let j = 0;
          const newObj = {};

          // For each key in the object
          for (const key in answer) {
            newObj[q.options[j].type] = valuesFormatter(
              answer[key],
              q.options[j].type
            );
            j++;
          }

          // Add this new object to the existing array
          payload[q.name].push(newObj);

          return;
        }

        // If the answer is an object (not an array)
        if (typeof answer === 'object') {
          for (const key in answer) {
            const answerName1 = `${q.name}_${key}`;
            payload[answerName1] = answer[key];
          }
          // early return, goes to next iteration
          return;
        }

        // Otherwise, it's just an array of values. Put the array as the answer.
        payload[q.name] = answers[answerId];
      });

      continue;

      // If the values are in an object
    } else if (typeof answers[answerId] === 'object' && q.options) {
      let i = 1;
      for (const option of q.options) {
        payload[option.name] = answers[answerId][i];
        i++;
      }
      continue;
    }

    // If there's a type, we can try and format it
    if (q.type) {
      payload[q.name] = valuesFormatter(answers[answerId], q.type);
      continue;
    }

    // Otherwise, just use the name as the key
    payload[q.name] = answers[answerId];
  }
  return payload;
};

/**
 * randId
 * Generate a random ID based on Math.random().
 * @param min Number
 * @param max Number
 * @returns string
 */
export const randId = (min = 2, max = 6) =>
  Math.random().toString(36).substr(min, max);
