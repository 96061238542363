import { years, otherYears } from './years';
import { makes, otherMakes } from './make';
import { combineReducers } from 'redux';

export default {
  'auto-zip': {
    currentQuestion: 1,
    total: 1,
    questions: {
      q1: {
        name: 'zip',
        label: 'Enter Zip Code to begin',
        formType: 'zip-code',
        type: 'zip-code',
        autoNext: false,
        btnLabel: 'Check Rates',
        validate: {
          required: true,
          length: 5,
        },
      },
    },
    info: {
      firstname: '',
    },
    answers: {},
    errors: {},
  },
  medicare: {
    currentQuestion: 1,
    total: 16,
    questions: {
      q1: {
        name: 'is_in_medicare',
        label: 'Are you currently enrolled in Medicare Parts A or B?',
        type: 'is-in-medicare',
        formType: 'select',
        autoNext: true,
        options: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
      },
      q2: {
        name: 'dob',
        label: 'What is your date of birth?',
        type: 'dob',
        formType: 'birthday',
        autoNext: false,
        placeholder: 'yyyy/mm/dd',
        btnLabel: 'Continue',
      },
      q3: {
        name: 'window',
        label: 'When would you like coverage?',
        type: 'window',
        formType: 'select',
        autoNext: true,
        options: [
          {
            label: 'Immediately',
            value: 'immediately',
          },
          {
            label: 'Within 2 months',
            value: 'two_months',
          },
        ],
        skipIf: { 1: 'Yes' },
      },
      q4: {
        name: 'interests',
        label: 'What benefits are most important to you?',
        type: 'interests',
        formType: 'multi-select',
        autoNext: false,
        btnLabel: 'Continue',
        options: [
          {
            label: 'Emergency Care/Hospitalization',
            name: 'emergency_care_hospitalization',
          },
          {
            label: 'Preventative Care',
            name: 'preventative_care',
          },
          {
            label: 'Dental',
            name: 'dental',
          },
          {
            label: 'Vision',
            name: 'vision',
          },
          {
            label: 'Telehealth',
            name: 'telehealth',
          },
          {
            label: 'Coverage for pre-existing conditions',
            name: 'coverage_for_preexisting_conditions',
          },
        ],
        validate: {
          required: true,
        },
        skipIf: { 1: 'Yes' },
      },
      q5: {
        name: 'gender',
        label: 'What is your gender?',
        type: 'gender',
        formType: 'select',
        autoNext: true,
        options: [
          {
            label: 'Female',
            value: 'female',
          },

          {
            label: 'Male',
            value: 'male',
          },
          {
            label: 'Non-Binary',
            value: 'non-ninary',
          },
        ],
      },
      q6: {
        name: 'is_smoker',
        label: 'Have you used Tobacco Products within the last 12 months?',
        type: 'is_smoker',
        formType: 'select',
        autoNext: true,
        options: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
        skipIf: { 1: 'Yes' },
      },
      q7: {
        name: 'include_spouse_in_plan',
        label:
          'Are you looking to include your <strong>spouse</strong> in your Health Insurance plan?',
        type: 'include_spouse_in_plan',
        formType: 'select',
        autoNext: true,
        options: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
        skipIf: { 1: 'Yes' },
        forSubmit: {
          omit: true,
        },
        notifications: [
          {
            q_to_compare: 6,
            show_if_value_is: 'No',
            message:
              'Important tip: As a non-smoker you may qualify for our lowest rates',
          },
          {
            q_to_compare: 6,
            show_if_value_is: 'Yes',
            message: 'Affordable plans for Tobacco Users',
          },
        ],
      },
      q8: {
        name: 'spouse_gender',
        label: "What is your spouse's gender?",
        type: 'gender',
        formType: 'select',
        autoNext: true,
        options: [
          {
            label: 'Female',
            value: 'female',
          },

          {
            label: 'Male',
            value: 'male',
          },
          {
            label: 'Non-Binary',
            value: 'non-ninary',
          },
        ],
        skipIf: { 1: 'Yes', 7: 'No' },
        forSubmit: { group_as: 'spouse' },
      },
      q9: {
        name: 'spouse_date_of_birth',
        label: "What is your spouse's date of birth??",
        type: 'dob',
        formType: 'birthday',
        autoNext: false,
        placeholder: 'yyyy/mm/dd',
        btnLabel: 'Continue',
        validate: {
          required: false,
          regex: 'birthdate',
        },
        skipIf: { 1: 'Yes', 7: 'No' },
        forSubmit: { group_as: 'spouse' },
      },
      q10: {
        name: 'has_spouse_used_tobacco',
        label:
          'Has your spouse used <strong>tobacco products</strong> within the last 12 months?',
        type: 'is_smoker',
        formType: 'select',
        autoNext: true,
        options: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
        skipIf: { 1: 'Yes', 7: 'No' },
        forSubmit: { group_as: 'spouse' },
      },
      q11: {
        name: 'include_children_in_plan',
        label:
          'Are you looking to include your <strong>children</strong> in your Health Insurance plan?',
        type: 'include_children_in_plan',
        formType: 'select',
        autoNext: true,
        options: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
        skipIf: { 1: 'Yes' },
        forSubmit: {
          omit: true,
        },
      },
      q12: {
        name: 'children',
        label: 'Add your children below',
        type: 'children',
        formType: 'add-children',
        autoNext: false,
        btnLabel: 'Continue',
        options: [
          {
            name: 'dob',
            label: 'Date of Birth',
            type: 'dob',
            placeholder: 'yyyy/mm/dd',
            id: 'dob',
            formType: 'birthday',
            validate: {
              required: true,
              regex: 'birthdate',
            },
          },
          {
            name: 'gender',
            label: 'Gender',
            id: 'gender',
            type: 'gender',
            formType: 'toggle',
            validate: {
              required: true,
              message: "Please select child's gender",
            },
          },
          {
            name: 'is_smoker',
            label: 'Smoker?',
            id: 'is_smoker',
            type: 'is_smoker',
            formType: 'toggle',
          },
        ],
        skipIf: { 1: 'Yes', 11: 'No' },
      },
      q13: {
        name: 'zip',
        label: 'What is your zip code?',
        formType: 'zip-code',
        type: 'zip_code',
        autoNext: false,
        btnLabel: 'Continue',
        validate: {
          required: true,
          length: 5,
          message: 'Enter a valid zip code',
        },
      },
      q14: {
        name: 'name',
        label: 'What is your name?',
        type: 'name',
        formType: 'level-select',
        autoNext: false,
        btnLabel: 'Continue',
        validate: {
          required: true,
        },
        forSubmit: { flatten: true },
        options: [
          {
            name: 'name_first',
            id: 1,
            formType: 'text',
            itemLabel: 'First Name',
            validate: {
              required: true,
              message: 'First name is required',
            },
          },
          {
            name: 'name_last',
            id: 2,
            formType: 'text',
            itemLabel: 'Last Name',
            validate: {
              required: true,
              message: 'Last name is required',
            },
          },
        ],
      },
      q15: {
        name: 'email',
        label: 'What is your email?',
        formType: 'email',
        autoNext: false,
        btnLabel: 'Continue',
        validate: {
          required: true,
          regex: 'email',
          message: 'Please provide a valid email',
        },
      },
      q16: {
        name: 'mobile_phone',
        label:
          'Last step! Your results are ready. Mobile or home phone number.',
        type: 'phone',
        formType: 'phone',
        autoNext: false,
        btnLabel: 'View My Results',
        validate: {
          required: true,
          regex: 'phone',
          message: 'Please enter a valid phone number',
        },
      },
    },
    info: {},
    answers: {},
    errors: {},
  },
  'auto-full': {
    currentQuestion: 1,
    total: 18,
    questions: {
      q1: {
        name: 'zip',
        label: 'Enter Zip Code to begin',
        formType: 'zip-code',
        type: 'zip-code',
        autoNext: false,
        btnLabel: 'Check Rates',
        validate: {
          required: true,
          length: 5,
        },
      },
      q2: {
        name: 'vehicle_1_year',
        label: 'Select Your Vehicle Year',
        type: 'vehicle_year',
        formType: 'select',
        autoNext: true,
        options: years,
        otherOptions: otherYears,
        otherOptionsLabel: 'Previous Years',
      },
      q3: {
        name: 'vehicle_1_make',
        label: 'Select Your Vehicle Make',
        type: 'vehicle_make',
        formType: 'select',
        autoNext: true,
        options: makes,
        otherOptions: otherMakes,
        otherOptionsLabel: 'Other Makes',
      },
      q4: {
        name: 'vehicle_1_model',
        label: 'Select Your Vehicle Model',
        type: 'vehicle_model',
        formType: 'vehicle-model',
        autoNext: true,
        options: [],
      },
      q5: {
        label: 'Add Second Vehicle? (Save Additional 20%)',
        type: 'vehicle_add',
        formType: 'select',
        autoNext: true,
        options: ['Yes', 'No'],
      },
      q6: {
        name: 'vehicle_2_year',
        label: 'Select Second Vehicle Year',
        type: 'vehicle_year',
        formType: 'select',
        autoNext: true,
        options: years,
        skipIf: { 5: 'No' },
      },
      q7: {
        name: 'vehicle_2_make',
        label: 'Select Second Vehicle Make',
        type: 'vehicle_make',
        formType: 'select',
        autoNext: true,
        options: makes,
        otherOptions: otherMakes,
        otherOptionsLabel: 'Other Makes',
        skipIf: { 5: 'No' },
      },

      q8: {
        name: 'vehicle_2_model',
        label: 'Select Second Vehicle Model',
        type: 'vehicle_model',
        formType: 'vehicle-model',
        autoNext: true,
        options: [],
        skipIf: { 5: 'No' },
      },
      q9: {
        name: 'insured_recently',
        label: 'Have you had auto insurance in the past 30 days?',
        type: 'insurance_yn',
        formType: 'select',
        autoNext: true,
        options: ['Yes', 'No'],
      },
      q10: {
        name: 'gender',
        label: 'Gender',
        type: 'gender',
        formType: 'select',
        autoNext: true,
        options: ['Male', 'Female', 'Non-Binary'],
      },
      q11: {
        name: 'is_married',
        label: 'Are you currently married?',
        type: 'married_yn',
        formType: 'select',
        autoNext: true,
        options: ['Yes', 'No'],
      },
      q12: {
        name: 'is_homeowner',
        label: 'Homeowner?',
        type: 'homeowner_yn',
        formType: 'select',
        autoNext: true,
        options: ['Yes', 'No'],
      },
      q13: {
        name: 'receive_quotes',
        label:
          'Would you like to also receive renters insurance policy quotes? You may be able to bundle and save even more on your auto policy.',
        type: 'renters_quotes_yn',
        formType: 'select',
        autoNext: true,
        options: ['Yes', 'No'],
      },

      q14: {
        label: 'Has anyone on this policy had:',
        type: 'accident_yn',
        formType: 'level-select',
        autoNext: false,
        btnLabel: 'Continue',
        forSubmit: { flatten: true },
        options: [
          {
            name: 'record_has_fault_accident_3y',
            id: 1,
            label:
              'An at-fault accident in the past <strong>three (3) years</strong>?',
            options: ['Yes', 'No'],
            formType: 'select',
            validate: {
              required: true,
            },
          },
          {
            name: 'record_has_multiple_tickets_3y',
            id: 2,
            label:
              '<strong>Two (2) or more</strong> tickets in the past <strong>three (3) years</strong>?',
            options: ['Yes', 'No'],
            formType: 'select',
            validate: {
              required: true,
            },
          },
          {
            name: 'record_has_dui_3y',
            id: 3,
            label:
              'A DUI conviction in the past <strong>three (3) years</strong>?',
            options: ['Yes', 'No'],
            formType: 'select',
            validate: {
              required: true,
            },
          },
        ],
      },
      q15: {
        name: 'military_member',
        label:
          'Are either you or your spouse an active member, or an honorably discharged veteran of the US military?',
        type: 'military_yn',
        formType: 'select',
        autoNext: true,
        options: ['Yes', 'No'],
      },
      q16: {
        name: 'birthdate',
        label: 'Birthdate',
        type: 'birthday',
        formType: 'birthday',
        autoNext: false,
        placeholder: 'yyyy/mm/dd',
        btnLabel: 'Continue',
        validate: {
          required: true,
          regex: 'birthdate',
        },
      },
      q17: {
        type: 'info_yn',
        formType: 'level-select',
        autoNext: false,
        btnLabel: 'Continue',
        forSubmit: { flatten: true },
        options: [
          {
            name: 'name_first',
            id: 1,
            formType: 'text',
            itemLabel: 'First Name',
            validate: {
              required: true,
            },
          },
          {
            name: 'name_last',
            id: 2,
            formType: 'text',
            itemLabel: 'Last Name',
            validate: {
              required: true,
            },
          },
          {
            name: 'email',
            id: 3,
            formType: 'email',
            itemLabel: 'Email Address',
            validate: {
              required: true,
              regex: 'email',
            },
          },
        ],
      },
      q18: {
        label: 'Last Step!',
        type: 'address_el',
        formType: 'level-select',
        autoNext: false,
        btnLabel: 'Continue',
        forSubmit: { flatten: true },
        options: [
          {
            name: 'address_1',
            id: 1,
            formType: 'text',
            itemLabel: 'Address',
            validate: {
              required: true,
            },
          },
          {
            name: 'phone',
            id: 2,
            formType: 'phone',
            itemLabel: 'Phone Number',
            validate: {
              required: true,
              regex: 'phone',
            },
          },
        ],
      },
    },
    info: {
      firstname: '',
    },
    answers: {
      14: {
        1: 'No',
        2: 'No',
        3: 'No',
      },
    },
    errors: {},
  },
};
