export const makes = [
  'Buick',
  'Cadillac',
  'Chevrolet',
  'Chrysler',
  'Dodge',
  'Ford',
  'Honda',
  'Hyundai',
  'Jeep',
  'Kia',
  'Land Rover',
  'Lexus',
  'Mitsubishi',
  'Nissan',
  'Tesla',
  'Toyota',
];

export const otherMakes = [
  'Acura',
  'Audi',
  'BMW',
  'Fiat',
  'GEO',
  'GMC',
  'Hummer',
  'Infiniti',
  'Isuzu',
  'Jaguar',
  'Lincoln',
  'Lexus',
  'Mazda',
  'Mercedes-benz',
  'Mercury',
  'Mini',
  'Oldsmobile',
  'Plymouth',
  'Porsche',
  'Pontiac',
  'Subaru',
  'Saab',
  'Saturn',
  'Smart',
  'Suzuki',
  'Volkswagen',
  'Volvo',
  'Other Make',
];
